<template>
  <v-container fluid>
    <v-row>
      <v-col
          class="mb-5"
          cols="auto"
          md="12"
          sm="12"
      >
        <v-card>
          <v-data-table
              :footer-props="{
                pageText: '{0}-{1} od {2}',
                itemsPerPageText: 'Kompanija po strani'
              }"
              :headers="headers"
              :items="positions"
              :search="searchString"
              class="elevation-1"
              multi-sort
          >
            <template v-slot:item.created_at="{ item }">
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>

            <template v-slot:top>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Kompanije</v-toolbar-title>

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>

                <v-text-field
                    v-model="searchString"
                    append-icon="mdi-magnify"
                    hide-details
                    label="Pretraga (naziv)"
                    single-line
                ></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog
                    v-model="dialog"
                    max-width="700px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mb-2"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                      Nova kompanija
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ formTitle }}
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-form
                            ref="form"
                            v-model="formValid">
                          <v-row>
                            <v-col cols="12" hidden md="6" sm="12">
                              <v-text-field
                                  v-model="editedItem.id"
                                  label="Id"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.name"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Kompanija"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.address"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Adresa"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.pib"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="PIB"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.pdv"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="PDV"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.bank_account"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Žiro račun"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.contact"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Kontakt"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.email"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Email"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-file-input
                                  v-model="editedItem.image"
                                  :rules="fieldRules.fileRequireAndTypeRules.check(editedItem.id,editedItem.image)"
                                  accept="image/jpg, image/jpeg, image/png"
                                  counter
                                  label="Slika"
                                  prepend-icon="mdi-camera"
                                  show-size
                                  small-chips
                              ></v-file-input>
                            </v-col>

                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="red darken-1 font-weight-bold"
                          text
                          @click="close"
                      >
                        Zatvori
                      </v-btn>
                      <v-btn
                          :disabled="!enableSave"
                          color="green darken-1 font-weight-bold"
                          text
                          @click="validateForm"
                      >
                        Sačuvaj
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialogDelete"
                    max-width="650"
                >
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedItem.name }}
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          Da li ste sigurni da želite da izbrišete ovu kompaniju?
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="red darken-1 font-weight-bold"
                          text
                          @click="closeDelete"
                      >
                        Ne želim
                      </v-btn>
                      <v-btn
                          :disabled="!enableSave"
                          color="green darken-1 font-weight-bold"
                          text
                          @click="deleteItemConfirm"
                      >
                        Želim
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogImage"
                    height="auto"
                    width="auto"
                >
                  <v-card>
                    <v-img
                        v-if="itemImage"
                        :src="itemImage"
                        max-height="600"
                        max-width="600"
                        style="background-size: cover;"
                    ></v-img>
                    <v-img
                        v-else
                        aspect-ratio="2"
                        contain
                        height="250px"
                        src="@/assets/default-user.png"
                        style="background-size: cover;"
                        width="250px"
                    ></v-img>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2 center"
                      v-bind="attrs"
                      @click="showLogo(item)"
                      v-on="on"
                  >
                    mdi-fit-to-screen
                  </v-icon>

                </template>
                <span>Prikaži fotografiju</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      small
                      v-bind="attrs"
                      @click="editItem(item)"
                      v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Izmijeni</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      color="red darken-3"
                      small
                      v-bind="attrs"
                      @click="deleteItem(item)"
                      v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Izbriši</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-snackbar
          v-model="snackbar.active"
          :color="snackbar.color"
          :timeout="2000"
          bottom
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar.active = false">
            Zatvori
          </v-btn>
        </template>
      </v-snackbar>

    </v-row>

  </v-container>
</template>

<script>
import {companiesHeaders} from '@/mixins/data-table-headers'
import {fieldRules} from "@/mixins/field-rules";
import {defaultCompany} from "@/mixins/default-items";

export default {
  name: 'CompaniesPage',
  components: {},
  data: () => ({
    searchString: '',
    dialog: false,
    dialogDelete: false,
    headers: [],
    positions: [],
    formTitle: 'Novi unos',
    formValid: true,
    editedItem: {},
    fieldRules: null,
    enableSave: true,
    itemImage: null,
    dialogImage: false,
    snackbar: {
      active: false,
      color: '',
      text: ''
    },
  }),
  created() {
    this.headers = companiesHeaders
    this.fieldRules = fieldRules
    this.editedItem = Object.assign({}, defaultCompany)
    this.loadAllCompanies();
  },
  methods: {
    async loadAllCompanies() {
      await this.$store.dispatch('companies/getAllCompanies').then((res) => {
        this.positions = res.data
      })
    },

    editItem(item) {
      this.editedItem = this.positions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.formTitle = item.name
      this.dialog = true
    },

    deleteItem(item) {
      this.editedItem = this.positions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    validateForm() {
      if (this.$refs.form.validate())
        this.save()
    },

    createFormData() {
      let formData = new FormData();
      if(this.editedItem.image)
      {
        formData.append('logo', this.editedItem.image)
      }
      if (this.editedItem.id) {
        formData.append('_method', 'POST')
        formData.append('id', this.editedItem.id)
      } else {
        formData.append('id', '')
      }
      formData.append('pib', this.editedItem.pib)
      formData.append('pdv', this.editedItem.pdv)
      formData.append('address', this.editedItem.address)
      formData.append('bank_account', this.editedItem.bank_account)
      formData.append('contact', this.editedItem.contact)
      formData.append('email', this.editedItem.email)
      formData.append('name', this.editedItem.name)
      return formData
    },

    async save() {
      this.enableSave = false
      let path = 'saveCompany'

      if (this.editedItem.id) {
        path = 'updateCompany'
      }
      let formData = this.createFormData()

      await this.$store.dispatch('companies/' + path, formData).then((res) => {
        if (res.success) {
          this.loadAllCompanies();
          this.showSnackbar('Uspješno sacuvano', 'green');
          this.close()
        } else {
          this.showSnackbar('Došlo je do greške, prijavite podršci', 'red');
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$store.dispatch('errorMessages/errorMapper', error.response.data.message).then(
              e => this.showSnackbar(e, 'red')
          )
        } else {
          this.showSnackbar('Došlo je do greške, prijavite podršci', 'red');
        }
      }).finally(() => {
        this.enableSave = true
        this.formTitle = "Novi unos"
      })
    },

    async deleteItemConfirm() {
      await this.$store.dispatch('companies/deleteCompany', this.editedItem).then((res) => {
        if (res.success) {
          this.loadAllCompanies();
          this.showSnackbar('Uspješno obrisano', 'green');
          this.closeDelete()
        } else {
          this.showSnackbar('Došlo je do greške, prijavite podršci', 'red');
        }
      }).catch(() => {
        this.showSnackbar('Došlo je do greške, prijavite podršci', 'red');
      }).finally(() => {
        this.closeDelete()
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultCompany)
      })
      this.$refs.form.resetValidation()
      this.formTitle = "Novi unos"
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultCompany)
      })
    },

    showSnackbar(text, color) {
      this.snackbar.active = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },

    showLogo(item) {
      this.dialogImage = true
      if (item.logo) {
        this.itemImage = process.env.VUE_APP_BACKEND_URL.concat(item.logo)
        this.itemImage = this.itemImage.replace('public','storage')
      } else {
        this.itemImage = null
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },
}
</script>
